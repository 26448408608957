import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { useContext, useEffect, useState } from 'react'
import { startStats } from '../../../services/StatsService'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { useNavigate } from 'react-router-dom'
import TeamAutocompleteComponent from '../../../components/TeamAutocompleteComponent/TeamAutocompleteComponent'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { listPlayersByTeamId, loadDefaultTeamPlayers } from '../../../services/TeamService'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'

function StartStatsGameComponent() {
    const [playersTeamA, setPlayersTeamA] = useState([])
    const [playersTeamB, setPlayersTeamB] = useState([])
    const [teamA, setTeamA] = useState('')
    const [teamB, setTeamB] = useState('')
    const language = navigator.language
    const [matchName, setMatchName] = useState('Rotations Stats '+ new Date().toLocaleDateString(language))
    const {userAuth} = useContext(AuthContext)
    const [date, setDate] = useState(new Date())
    const navigate = useNavigate()
    const types = [{value: 1, text: 'Game Season'}, {value: 2, text: 'Game Pre-Season'}, {value: 3, text: 'Practice Season'}, {value: 4, text: 'Practice Pre-Season'}, ]
    const [selectedType, setSelectedType] = useState(types[0].value)

    useEffect(()=>{
        teamA && handleSetPlayersTeamA()
    }, [teamA])
    
    useEffect(()=>{
        teamB && handleSetPlayersTeamB()
    }, [teamB])
    
    const handleSetPlayersTeamA = () => {
        if (teamA){
            listPlayersByTeamId(teamA.access_key).then((res)=>{
                setPlayersTeamA(res)
            })
        }
    }

    const handleSetPlayersTeamB = () => {
        if (teamB){
            listPlayersByTeamId(teamB.access_key).then((res)=>{
                setPlayersTeamB(res)
            })
        }
    }

    const handleStartStats = (statisticsType) => {
        var _playersTeamA = []
        playersTeamA.forEach((item)=>{
            _playersTeamA.push({
                player:{
                    access_key: item.access_key,
                    id: item.id,
                    first_name: item.first_name,
                    last_name: item.last_name,
                },
                number: item.player_number,
                position: null
            })
        })
        
        var _playersTeamB = []
        playersTeamB.forEach((item)=>{
            _playersTeamB.push({
                player:{
                    access_key: item.access_key,
                    id: item.id,
                    first_name: item.first_name,
                    last_name: item.last_name,
                },
                number: item.player_number,
                position: null
            })
        })
        
        startStats(matchName, statisticsType, selectedType, teamA, teamB, _playersTeamA, _playersTeamB, userAuth.user.access_key, date).then((res)=>{
            var matchId = null
            var matchAccessKey = null
            if (res) {
                matchId = res.matchId
                matchAccessKey = res.matchAccessKey
            }
            
            matchId && navigate('/app/statistics/'+matchAccessKey)
        })
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <ColumnGroup header="First Name" align={'center'} />
                <Column header="Last Name" align={'center'} />
                <Column header="#" align={'center'} />
            </Row>
        </ColumnGroup>
    );
    
    return (
        <div className='flex flex-column gap-2'>
            <ConfirmDialog />
            <div className='flex gap-2'>
                <div className="flex flex-column gap-2 w-full">
                    <label htmlFor="match" className='text-l font-bold'>Title</label>
                    <InputText name='match' value={matchName} className='w-full p-inputtext-sm' onChange={(e)=>setMatchName(e.target.value)} />
                </div>
                <div className="flex flex-column gap-2 w-full">
                    <label htmlFor="date" className='font-bold'>Date</label>
                    <Calendar name='date' value={date} className='w-full p-inputtext-sm' onChange={(e)=>setDate(e.target.value)} />
                </div>
                <div className="flex flex-column gap-2 w-full">
                    <label htmlFor="date" className='font-bold'>Type</label>
                    <Dropdown value={selectedType} className='w-full p-inputtext-sm' onChange={(e) => setSelectedType(e.value)} options={types} optionLabel="text"
                    placeholder="Select a Type" />
                </div>
            </div>
            <div className='flex gap-2 w-full'>
                <div className='flex flex-column gap-2 flex-1'>
                    <div className='text-l font-bold'>Your Team</div>
                    <TeamAutocompleteComponent setTeam={(e)=>setTeamA(e)} team={teamA} size='p-inputtext-sm' />
                    <label htmlFor="match" className='font-bold'>Team Players</label>
                    <DataTable showGridlines stripedRows value={playersTeamA} headerColumnGroup={headerGroup} tableStyle={{ minWidth: '10rem' }}>
                        <Column align={'left'} field="first_name" className='p-2' />
                        <Column align={'left'} field="last_name" className='p-2' />
                        <Column className='p-2' align={'right'} field='player_number' />
                    </DataTable>
                </div>
                <div className='flex flex-column gap-2 flex-1'>
                    <div className='text-l font-bold'>Opponent Team</div>
                    <TeamAutocompleteComponent setTeam={(e)=>setTeamB(e)} team={teamB} size='p-inputtext-sm' />
                    <label htmlFor="match" className='font-bold'>Team Players</label>
                    <DataTable showGridlines stripedRows value={playersTeamB} headerColumnGroup={headerGroup} tableStyle={{ minWidth: '10rem' }}>
                        <Column align={'left'} field="first_name" className='p-2' />
                        <Column align={'left'} field="last_name" className='p-2' />
                        <Column className='p-2' align={'right'} field='player_number' />
                    </DataTable>
                </div>
            </div>
            <div className='flex gap-2'>
                <Button label='Start Rotations Stats' className='w-max' onClick={()=>handleStartStats('game')} />
            </div>
        </div>
    )
}

export default StartStatsGameComponent