
import { toast } from 'react-toastify';
import axios from 'axios';
// import {firebase_auth} from '../firebase';

export const startStats = async (
    matchName, 
    statysticsType, 
    type, 
    teamA, 
    teamB, 
    playersTeamA,
    playersTeamB,
    user_access_key, date) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/start_stats`, {
            matchName, 
            statysticsType,
            type,
            teamA, 
            teamB, 
            playersTeamA,
            playersTeamB,
            user_access_key, date})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getMatchByAccessKey = async (access_key) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/stats/get_match_by_access_key/${access_key}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getRotationsStatsByMatch = async (access_key, set, rally_phase) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/get_rotations_stats_match`,{access_key, set, rally_phase})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getRotationsStatsByDate = async (team_access_key, start_date, end_date, rally_phase, match_type) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/get_rotations_stats_date`,{team_access_key, start_date, end_date, rally_phase, match_type})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getGameStatsRallyPhaseByMatch = async (match_access_key, set) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/get_rally_phase_stats_match/`,{match_access_key,set})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getGameStatsRallyPhaseByDates = async (team_access_key, start_date, end_date) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/get_rally_phase_stats_date`,{team_access_key, start_date, end_date})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getAttackDistributionEfficiency = async (match_access_key, set) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/get_set_distribution_attack_efficiency/`, {match_access_key, set})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listRallyActionsByMatch = async (access_key, page = 1) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/stats/list_rally_actions_by_match/${access_key}/${page}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const updateActionPlayer = async (id, action_id, user_id, quality_id) => {
    try {
        return await axios.put(`${process.env.REACT_APP_API_URL}/api/stats/update_player_action`,{id, action_id, user_id, quality_id})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const deleteActionPlayer = async (id) => {
    try {
        return await axios.delete(`${process.env.REACT_APP_API_URL}/api/stats/delete_player_action/${id}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getUserStats = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/stats/get_user_stats/${id}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getPracticeStats = async (access_key, set) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/stats/get_practice_stats/${access_key}/${set}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getTeamStats = async (access_key, startDate, endDate, type) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/get_team_stats/${access_key}`,{startDate, endDate, type})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const loadMatchDataStatsAnalysis = async (match_access_key) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/stats/load_match_data_stats_analysis/${match_access_key}`)
        .then((response)=>{
            return response.data[0]
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listMyStats = async (user_access_key, page = 1, type = 'all') => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/stats/list_my_stats/${user_access_key}/${page}/${type}`)
        .then((response)=>{
            return response?.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const deleteMatch = async (match_access_key) => {
    try {
        return await axios.delete(`${process.env.REACT_APP_API_URL}/api/stats/delete/${match_access_key}`)
        .then((response)=>{
            toast.success('Match deleted!')
            return response.data[0]
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const createRally = async (rally) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/create_rally`,rally)
        .then((res)=>{
            return true
        }).catch((error)=>{
            console.log(error)
            toast.error('Rally saving error!')
            return false        
        })
    } catch (error) {
        console.log(error)
        toast.error('Rally saving error!')
        return false        
    }
}

export const getPlayerStatsHistory = async (user, start_date, end_date) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/get_player_stats_history`,{user_id: user.player_id, start_date, end_date})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getTeamStatsHistory = async (team_access_key, start_date, end_date) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/get_team_stats_history`,{team_access_key, start_date, end_date})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getPositionStatsHistory = async (team_access_key, position_id, start_date, end_date) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/stats/get_position_stats_history`,{team_access_key, position_id, start_date, end_date})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}



