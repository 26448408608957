import React, { useState } from 'react'
import { Checkbox } from 'primereact/checkbox'

function ManageTeamButtonsComponent({players, setPlayers, activePlayersContext, setActivePlayersContext}) {

    const [initialLocalData, setInitialLocalData] = useState({})
    const handlePlayerBoxColor = (skill) => {
        if (skill === 10) return 'border-blue-400 bg-blue-100' 
        if (skill === 11) return 'border-bluegray-400 bg-bluegray-100'
        if (skill === 9) return 'border-yellow-400 bg-yellow-100'
        if (skill === 14) return 'border-purple-400 bg-purple-100'
        if (skill === 12) return 'border-pink-400 bg-pink-100'
    }

    const handleActivePlayers = (element) => {
        // Create new copies of the state to avoid mutation
        const _activePlayers = [...activePlayersContext];
        const _players = [...players];
    
        const playerIdx = _players.findIndex(player => Number(player.player.id) === Number(element.value));
        const player = _players[playerIdx];
        
        // If player is checked
        if (element.checked) {
            _activePlayers.push(element.value);
    
            if (!player.position && !player.courtPosition) {
                let _position = 0;
                // Find the next available position
                for (let position = 1; position <= 6; position++) {
                    const indexPosition = _players.findIndex(e => Number(e.position) === position);
                    if (indexPosition === -1) {
                        _position = position;
                        break;
                    }
                }
    
                // Update player position
                const updatedPlayer = { ...player, position: _position, courtPosition: _position };
                _players[playerIdx] = updatedPlayer;
            } else {
                // If already has a position, clear it
                const updatedPlayer = { ...player, position: '', courtPosition: '' };
                _players[playerIdx] = updatedPlayer;
            }
        } else {
            // If unchecked, remove from active players
            const indexToRemove = _activePlayers.indexOf(element.value);
            if (indexToRemove !== -1) {
                _activePlayers.splice(indexToRemove, 1);
            }
            // If already has a position, clear it
            const updatedPlayer = { ...player, position: '', courtPosition: '' };
            _players[playerIdx] = updatedPlayer;
        }
        setPlayers([..._players]); // Use a new array to set state
    
        setActivePlayersContext([..._activePlayers]);
    
        // Update initialLocalData with the active players
        const _initialLocalData = { ...initialLocalData, activePlayers: _activePlayers };
        setInitialLocalData(_initialLocalData);
    };
    
    return (
        <div>
                {
                    players && 
                    <div className='flex flex-column gap-2 mt-4'>
                        <div className='flex flex-wrap gap-2 justify-content-between'>
                            <div className='flex flex-wrap gap-2 justify-content-start'>
                                {players?.filter(e=>e.player.skill === 10).map((element, key)=>(
                                    <div key={key}
                                    className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem ${handlePlayerBoxColor(element.player.skill)}`}>
                                        <div className='text-xl flex gap-1 align-items-center font-bold'>
                                            <Checkbox value={element.player.id} 
                                            checked={activePlayersContext?.findIndex(e=> e === element.player.id) >= 0} 
                                            onChange={handleActivePlayers} />
                                            {element.number < 10 ? `0${element.number}` : element.number}
                                        </div>
                                        <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                            {element.player.first_name} {element.player.last_name}
                                        </div>
                                    </div> 
                                    ))
                                }
                            </div>
                            <div className='flex flex-wrap gap-2 justify-content-start'>
                                {players?.filter(e=>e.player.skill === 11).map((element, key)=>(
                                    <div key={key} 
                                    className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem ${handlePlayerBoxColor(element.player.skill)}`}>
                                        <div className='text-xl flex gap-1 align-items-center font-bold'>
                                            <Checkbox value={element.player.id} 
                                            checked={activePlayersContext.findIndex(e=> e === element.player.id) >= 0} 
                                            onChange={handleActivePlayers} />
                                            {element.number < 10 ? `0${element.number}` : element.number}
                                        </div>
                                        <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                            {element.player.first_name} {element.player.last_name}
                                        </div>
                                    </div> 
                                    ))
                                }
                            </div>
                            <div className='flex flex-wrap gap-2 justify-content-start'>
                                {players?.filter(e=>e.player.skill === 9).map((element, key)=>(
                                    <div key={key} 
                                    className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem ${handlePlayerBoxColor(element.player.skill)}`}>
                                        <div className='text-xl flex gap-1 align-items-center font-bold'>
                                            <Checkbox value={element.player.id} 
                                            checked={activePlayersContext.findIndex(e=> e === element.player.id) >= 0} 
                                            onChange={handleActivePlayers} />
                                            {element.number < 10 ? `0${element.number}` : element.number}
                                        </div>
                                        <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                            {element.player.first_name} {element.player.last_name}
                                        </div>
                                    </div> 
                                    ))
                                }
                            </div>
                            <div className='flex flex-wrap gap-2 justify-content-start'>
                                {players?.filter(e=>e.player.skill === 14).map((element, key)=>(
                                    <div key={key} 
                                    className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem ${handlePlayerBoxColor(element.player.skill)}`}>
                                        <div className='text-xl flex gap-1 align-items-center font-bold'>
                                            <Checkbox value={element.player.id} 
                                            checked={activePlayersContext.findIndex(e=> e === element.player.id) >= 0} 
                                            onChange={handleActivePlayers} />
                                            {element.number < 10 ? `0${element.number}` : element.number}
                                        </div>
                                        <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                            {element.player.first_name} {element.player.last_name}
                                        </div>
                                    </div> 
                                    ))
                                }
                            </div>
                            <div className='flex flex-wrap gap-2 justify-content-start'>
                                {players?.filter(e=>e.player.skill === 12).map((element, key)=>(
                                    <div key={key} 
                                    className={`flex flex-column gap-2 p-2 border-2 border-round w-6rem ${handlePlayerBoxColor(element.player.skill)}`}>
                                        <div className='text-xl flex gap-1 align-items-center font-bold'>
                                            <Checkbox value={element.player.id} 
                                            checked={activePlayersContext.findIndex(e=> e === element.player.id) >= 0} 
                                            onChange={handleActivePlayers} />
                                            {element.number < 10 ? `0${element.number}` : element.number}
                                        </div>
                                        <div className='text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                            {element.player.first_name} {element.player.last_name}
                                        </div>
                                    </div> 
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }
        </div>
    )
}

export default ManageTeamButtonsComponent