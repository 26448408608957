import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAttackDistributionEfficiency, getGameStatsRallyPhaseByMatch, getRotationsStatsByMatch, loadMatchDataStatsAnalysis } from '../../../services/StatsService'
import ShowGameStatsItemComponent from './ShowGameStatsItemComponent'
import { Button } from 'primereact/button'
import { MultiSelect } from 'primereact/multiselect';
import StatisticsContext from './StatisticsContext'
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Accordion, AccordionTab } from 'primereact/accordion'
import ShowPracticeStats from './ShowPracticeStats'
        

function ShowGameStats({refresData = false, setRefreshReportData}) {
    const {access_key} = useParams()
    const [setAttackEfficiencyList, setSetAttackEfficiencyList] = useState()
    const {rotationA, rotationB, gridData, currentActionRefContext, activeTeam} = useContext(StatisticsContext)
    const [data, setData] = useState()
    const originalDataRef = useRef()
    const [teams, setTeams] = useState([]);
    const [match, setMatch] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const rally_phases = ['FBSO', 'SO', 'TRA']    
    
    const [selectedRallyPhases, setSelectedRallyPhases] = useState(rally_phases);
    const rotations = ["1", "2", "3", "4", "5", "6"];
    const [selectedRotations, setSelectedRotations] = useState(rotations);
    const actions = ["Attack", "Set", "Reception", "Serve"];
    const [selectedActions, setSelectedActions] = useState(actions);
    const [checkedLiveRotations, setCheckedLiveRotations] = useState(false)
    const [setSelected, setSetSelected] = useState([])
    const [teamAStatsAvg, setTeamAStatsAvg] = useState()
    const [teamBStatsAvg, setTeamBStatsAvg] = useState()
    const [rotationsStatsA, setRotationsStatsA] = useState()
    const [rotationsStatsB, setRotationsStatsB] = useState()
    const [expandedRows, setExpandedRows] = useState([]);
    const [rotationsAAvg, setRotationsAAvg] = useState([{
        rotation: 1,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 2,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 3,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 4,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 5,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 6,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },]) 
    const [rotationsBAvg, setRotationsBAvg] = useState([{
        rotation: 1,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 2,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 3,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 4,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 5,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },{
        rotation: 6,
        fbso_point: 0,
        so_point: 0,
        tra_point: 0,
        fbso_error: 0,
        so_error: 0,
        tra_error: 0,
    },]) 

    const sets = [
        {value: 1, label: 'Set 1'},
        {value: 2, label: 'Set 2'},
        {value: 3, label: 'Set 3'},
        {value: 4, label: 'Set 4'},
        {value: 5, label: 'Set 5'}]

    useEffect(()=>{
        access_key && loadMatchDataStatsAnalysis(access_key).then((res)=>{
            setMatch(res)
        })
        handleGetStats()
    },[access_key])

    useEffect(()=>{
        teams && teams.length >= 1 && handleGetGameStatsRallyPhaseByMatch()
    },[teams])

    useEffect(()=>{
        refresData && currentActionRefContext?.current?.number === "ser" && handleGetStats()
    },[refresData, currentActionRefContext?.current])

    const handleGetGameStatsRallyPhaseByMatch = () => {
        access_key && getGameStatsRallyPhaseByMatch(access_key, setSelected).then((res)=>{
            var _rotationsA = []
            var _rotationsB = []

            var _teamASum = {
                fbso_point: 0,
                so_point: 0,
                tra_point: 0,
                fbso_error: 0,
                so_error: 0,
                tra_error: 0,
            }
            var _teamBSum = {
                fbso_point: 0,
                so_point: 0,
                tra_point: 0,
                fbso_error: 0,
                so_error: 0,
                tra_error: 0,
            }

            var _rotationsAAvg = [...rotationsAAvg]
            var _rotationsBAvg = [...rotationsBAvg]

            res.stats_team_a.forEach(e=>{
                var _teamA = {
                    fbso_percentage: 0,
                    so_percentage: 0,
                    tra_percentage: 0,
                }

                _teamA.name = e?.team_name_a

                _teamA.fbso_percentage = Number(e.fbso_point_a) ? Number(e.fbso_point_a)/(Number(e.fbso_point_a)+Number(e.fbso_error_a)) : 0
                _teamASum.fbso_point += Number(e.fbso_point_a)
                _teamASum.fbso_error += Number(e.fbso_error_a)
                _rotationsAAvg[e.rotation-1].fbso_point += Number(e.fbso_point_a)
                _rotationsAAvg[e.rotation-1].fbso_error += Number(e.fbso_error_a)

                _teamA.so_percentage = Number(e.so_point_a) ? Number(e.so_point_a)/(Number(e.so_point_a)+Number(e.so_error_a)) : 0
                _teamASum.so_point += Number(e.so_point_a)
                _teamASum.so_error += Number(e.so_error_a)
                _rotationsAAvg[e.rotation-1].so_point += Number(e.so_point_a)
                _rotationsAAvg[e.rotation-1].so_error += Number(e.so_error_a)

                _teamA.tra_percentage = Number(e.tra_point_a) ? Number(e.tra_point_a)/(Number(e.tra_point_a)+Number(e.tra_error_a)) : 0
                _teamASum.tra_point += Number(e.tra_point_a)
                _teamASum.tra_error += Number(e.tra_error_a)
                _rotationsAAvg[e.rotation-1].tra_point += Number(e.tra_point_a)
                _rotationsAAvg[e.rotation-1].tra_error += Number(e.tra_error_a)

                _rotationsA.push({
                    ..._teamA,
                    rotation: e.rotation,
                    set_match: e.set_match
                })
            })

            res.stats_team_b.forEach(e=>{
                var _teamB = {
                    fbso_percentage: 0,
                    so_percentage: 0,
                    tra_percentage: 0,
                }

                _teamB.name = e?.team_name_b

                _teamB.fbso_percentage = Number(e.fbso_point_b) ? Number(e.fbso_point_b)/(Number(e.fbso_point_b)+Number(e.fbso_error_b)) : 0
                _teamBSum.fbso_point += Number(e.fbso_point_b)
                _teamBSum.fbso_error += Number(e.fbso_error_b)
                _rotationsBAvg[e.rotation-1].fbso_point += Number(e.fbso_point_b)
                _rotationsBAvg[e.rotation-1].fbso_error += Number(e.fbso_error_b)

                _teamB.so_percentage = Number(e.so_point_b) ? Number(e.so_point_b)/(Number(e.so_point_b)+Number(e.so_error_b)) : 0
                _teamBSum.so_point += Number(e.so_point_b)
                _teamBSum.so_error += Number(e.so_error_b)
                _rotationsBAvg[e.rotation-1].so_point += Number(e.so_point_b)
                _rotationsBAvg[e.rotation-1].so_error += Number(e.so_error_b)

                _teamB.tra_percentage = Number(e.tra_point_b) ? Number(e.tra_point_b)/(Number(e.tra_point_b)+Number(e.tra_error_b)) : 0
                _teamBSum.tra_point += Number(e.tra_point_b)
                _teamBSum.tra_error += Number(e.tra_error_b)
                _rotationsBAvg[e.rotation-1].tra_point += Number(e.tra_point_b)
                _rotationsBAvg[e.rotation-1].tra_error += Number(e.tra_error_b)

                _rotationsB.push({
                    ..._teamB,
                    rotation: e.rotation,
                    set_match: e.set_match
                })
            })
            setRotationsAAvg(_rotationsAAvg)
            setRotationsBAvg(_rotationsBAvg)
            setRotationsStatsA(_rotationsA)
            setRotationsStatsB(_rotationsB)

            const _teamAAvg = {
                name: res.stats_team_a[0].team_name_a,
                fbso_percentage: (_teamASum.fbso_point > 0) ? _teamASum.fbso_point / (_teamASum.fbso_point + _teamASum.fbso_error) : 0,
                so_percentage: (_teamASum.so_point > 0) ? _teamASum.so_point / (_teamASum.so_point + _teamASum.so_error) : 0,
                tra_percentage: (_teamASum.tra_point > 0) ? _teamASum.tra_point / (_teamASum.tra_point + _teamASum.tra_error) : 0,
            }
            
            const _teamBAvg = {
                name: res.stats_team_b[0].team_name_b,
                fbso_percentage: (_teamBSum.fbso_point > 0) ? _teamBSum.fbso_point / (_teamBSum.fbso_point + _teamBSum.fbso_error) : 0,
                so_percentage: (_teamBSum.so_point > 0) ? _teamBSum.so_point / (_teamBSum.so_point + _teamBSum.so_error) : 0,
                tra_percentage: (_teamBSum.tra_point > 0) ? _teamBSum.tra_point / (_teamBSum.tra_point + _teamBSum.tra_error) : 0,
            }
            setTeamAStatsAvg(_teamAAvg)
            setTeamBStatsAvg(_teamBAvg)
        })
    }

    const onRotationsChange = (e) => {
        setSelectedRotations(e.value.sort());
    }

    const onActionsChange = (e) => {
        setSelectedActions(e.value.sort());
    }

    const onTeamsChange = (e) => {
        setSelectedTeams(e.value);
    }

    const onRallyPhasesChange = (e) => {
        setSelectedRallyPhases(e.value);
    }

    const onSetsChange = (e) => {
        setSetSelected(e.value)
    }

    const handleGetStats = () => {
        access_key && getRotationsStatsByMatch(access_key, setSelected, selectedRallyPhases).then((res)=>{
            originalDataRef.current = res
            var _teams = []
            res?.forEach((item)=>{
                !_teams.includes(item.team_name) && _teams.push(item.team_name)
            })
            setTeams(_teams)
            setSelectedTeams(_teams)
            refreshCourtsData()

            getAttackDistributionEfficiency(access_key, setSelected).then((res)=>{
                setSetAttackEfficiencyList(res)
            })
        })
        setRefreshReportData && setRefreshReportData(false)
    }

    const refreshCourtsData = () => {
        if (checkedLiveRotations) {
            if (activeTeam && currentActionRefContext?.current && gridData && originalDataRef.current) {
                const _data = originalDataRef.current.filter(e=>
                    (
                        (
                            (e.rotation_id === rotationA && e.team_name === gridData.teamA.name)
                            ||
                            (e.rotation_id === rotationB && e.team_name === gridData.teamB.name)
                        ) && (
                            (
                                (currentActionRefContext?.current.number === "ser" && activeTeam === 1) && (
                                    (
                                        e.team_name === gridData.teamB.name && 
                                        (
                                            e.action_name === "Reception" || 
                                            e.action_name === "Set" || 
                                            e.action_name === "Attack"
                                        )
                                    ) 
                                    || 
                                    (
                                        e.team_name === gridData.teamA.name && 
                                        (
                                            e.action_name === "Serve" || 
                                            e.action_name === "Set" || 
                                            e.action_name === "Attack"
                                        )
                                    )
                                )
                            )
                            ||
                            (
                                (currentActionRefContext?.current.number === "ser" && activeTeam === 2) && (
                                    (
                                        e.team_name === gridData.teamA.name && 
                                        (
                                            e.action_name === "Reception" || 
                                            e.action_name === "Set" || 
                                            e.action_name === "Attack"
                                        )
                                    ) 
                                    || 
                                    (
                                        e.team_name === gridData.teamB.name && 
                                        (
                                            e.action_name === "Serve" || 
                                            e.action_name === "Set" || 
                                            e.action_name === "Attack"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
                setData(_data)
            }
        } else {
            setData(originalDataRef.current)
        }
    }

    const headerGroupTemplateA = (item) => {
        const _rotationsStatsA = rotationsAAvg[item.rotation-1]
        
        var fbso = (_rotationsStatsA.fbso_point > 0) ? (_rotationsStatsA.fbso_point/(_rotationsStatsA.fbso_error+_rotationsStatsA.fbso_point)) : 0
        var so = (_rotationsStatsA.so_point > 0) ? (_rotationsStatsA.so_point/(_rotationsStatsA.so_error+_rotationsStatsA.so_point)) : 0
        var tra = (_rotationsStatsA.tra_point > 0) ? (_rotationsStatsA.tra_point/(_rotationsStatsA.tra_error+_rotationsStatsA.tra_point)) : 0

        return (
            <React.Fragment>
                <span>Rotation {item.rotation}</span>
                <div className='bg-blue-100  flex justify-content-between'>
                    <div className="font-bold text-center p-1 w-2">Avg</div>
                    <div className="font-bold text-right p-1 flex-1">{(fbso*100).toFixed(2)}</div>
                    <div className="font-bold text-right p-1 flex-1">{(so*100).toFixed(2)}</div>
                    <div className="font-bold text-right p-1 flex-1">{(tra*100).toFixed(2)}</div>
                </div>
            </React.Fragment>
        );
    }

    const headerGroupTemplateB = (item) => {
        const _rotationsStatsB = rotationsBAvg[item.rotation-1]
        
        var fbso = (_rotationsStatsB.fbso_point > 0 || _rotationsStatsB.fbso_error > 0) ? (_rotationsStatsB.fbso_point/(_rotationsStatsB.fbso_error+_rotationsStatsB.fbso_point)) : 0
        var so = (_rotationsStatsB.so_point > 0 || _rotationsStatsB.so_error > 0) ? (_rotationsStatsB.so_point/(_rotationsStatsB.so_error+_rotationsStatsB.so_point)) : 0
        var tra = (_rotationsStatsB.tra_point > 0 || _rotationsStatsB.tra_error > 0) ? (_rotationsStatsB.tra_point/(_rotationsStatsB.tra_error+_rotationsStatsB.tra_point)) : 0

        return (
            <React.Fragment>
                <span>Rotation {item.rotation}</span>
                <div className='bg-blue-100  flex justify-content-between'>
                    <div className="font-bold text-center p-1 w-2">Avg</div>
                    <div className="font-bold text-right p-1 flex-1">{(fbso*100).toFixed(2)}</div>
                    <div className="font-bold text-right p-1 flex-1">{(so*100).toFixed(2)}</div>
                    <div className="font-bold text-right p-1 flex-1">{(tra*100).toFixed(2)}</div>
                </div>
            </React.Fragment>
        );
    }

    const formatNumber = (number) => {
        return ((Number(number)*100).toFixed(2))
    }

    return (
        <div className='flex flex-column'>
            <div className='text-lg mb-2'>Match: <b>{match?.matchTitle ? match?.matchTitle : ''}</b></div>
            <Accordion multiple activeIndex={[0]} unstyled={true} className='flex flex-column gap-1'>
                <AccordionTab header={<label className='ml-2 text-lg'>Filters</label>} headerClassName='flex flex-1 p-3 gap-2 bg-gray-100 border-1 border-gray-300 border-round'>
                    <div className='flex flex-wrap gap-2 align-items-end justify-content-start p-2'>
                        {gridData && <div className='flex flex-column'>
                            <label className='text-xs' htmlFor="liveRotation">Live Rotations</label>
                            <InputSwitch size={'small'} inputId="liveRotation" checked={checkedLiveRotations} onChange={()=>setCheckedLiveRotations(!checkedLiveRotations)} />
                        </div>}
                        <MultiSelect disabled={checkedLiveRotations} value={selectedRotations} onChange={onRotationsChange} options={rotations} 
                            placeholder="Select Rotations" />
                        <MultiSelect disabled={checkedLiveRotations} value={selectedActions} onChange={onActionsChange} options={actions} 
                            placeholder="Select Actions" />
                        <MultiSelect disabled={checkedLiveRotations} value={selectedTeams} onChange={onTeamsChange} options={teams} 
                            placeholder="Select Teams" />
                        <MultiSelect disabled={checkedLiveRotations} value={setSelected} onChange={onSetsChange} options={sets} 
                        placeholder="Select a Set" />
                        <MultiSelect disabled={checkedLiveRotations} value={selectedRallyPhases} onChange={onRallyPhasesChange} options={rally_phases} 
                            placeholder="Select FBSO/SO/BP" />
                        <Button label='Refresh Data' onClick={handleGetStats} />
                    </div>
                </AccordionTab>
                <AccordionTab header={<label className='ml-2 text-lg'>Individual Stats</label>} headerClassName='flex p-3 gap-2 bg-gray-100 border-1 border-gray-300 border-round'>
                    <ShowPracticeStats />
                </AccordionTab>
                <AccordionTab header={<label className='ml-2 text-lg'>Rotations</label>} headerClassName='flex p-3 gap-2 bg-gray-100 border-1 border-gray-300 border-round'>
                    <div className={'flex gap-2'}> 
                        <div className='flex flex-wrap w-full align-content-start justify-content-between gap-2 p-2'>
                            {data && data.map((item, key)=>(
                                (item.team_name+"" === teamAStatsAvg?.name && 
                                selectedTeams.includes(item.team_name+"") && 
                                selectedActions.includes(item.action_name+"") && 
                                selectedRotations.includes(item.rotation_id+"")) && 
                                <ShowGameStatsItemComponent item={item} key={key} teamAName={teamAStatsAvg?.name} setAttackEfficiencyList={setAttackEfficiencyList} />
                            ))}
                        </div>
                        <div className='flex flex-wrap w-full align-content-start justify-content-between gap-2 p-2'>
                            {data && data.map((item, key)=>(
                                (item.team_name+"" === teamBStatsAvg?.name && 
                                selectedTeams.includes(item.team_name+"") && 
                                selectedActions.includes(item.action_name+"") && 
                                selectedRotations.includes(item.rotation_id+"")) && 
                                <ShowGameStatsItemComponent item={item} key={key} teamAName={teamAStatsAvg?.name} setAttackEfficiencyList={setAttackEfficiencyList} />
                            ))}
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={<label className='ml-2 text-lg'>FBSO/SO/TRA</label>} headerClassName='flex p-3 gap-2 bg-gray-100 border-1 border-gray-300 border-round'>
                    <div className='flex flex-wrap justify-content-between gap-4 font-bold p-2'>
                        {teamAStatsAvg && rotationsStatsA &&
                        <div className='flex flex-1 gap-2 flex-column p-4 border-round' style={{backgroundColor: '#86bbd8'}}>
                            <div className='text-center text-xl'>{teamAStatsAvg?.name}</div>
                            <DataTable showGridlines stripedRows value={rotationsStatsA} rowGroupMode='subheader'
                            groupRowsBy="rotation" expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                            rowGroupHeaderTemplate={headerGroupTemplateA} >
                                <Column className='p-1 border-1 border-gray-900' align={'center'} field="set_match"
                                        footer="T" 
                                        alignHeader='center' headerClassName='p-1 border-1 border-gray-900' header="Set" />
                                
                                <Column className='p-1 border-1 border-gray-900' footer={(teamAStatsAvg.fbso_percentage*100).toFixed(2)} 
                                        alignHeader='center' headerClassName='p-1 border-1 border-gray-900' header="FBSO" 
                                        align={'right'} field={(item)=>formatNumber(item.fbso_percentage)} />
                                
                                <Column className='p-1 border-1 border-gray-900' footer={(teamAStatsAvg.so_percentage*100).toFixed(2)}
                                        headerClassName='p-1 border-1 border-gray-900' alignHeader='center' header="SO" 
                                        align={'right'} field={(item)=>formatNumber(item.so_percentage)}  />
                                
                                <Column header="BP" alignHeader={'center'} headerClassName='p-1 border-1 border-gray-900' 
                                        field={(item)=>formatNumber(item.tra_percentage)} className='p-1 border-1 border-gray-900' 
                                        footer={(teamAStatsAvg.tra_percentage*100).toFixed(2)} align={'right'} />
                            </DataTable>
                        </div>}
                        {teamBStatsAvg && rotationsStatsB &&
                        <div className='flex flex-1 gap-2 flex-column p-4 border-round' style={{backgroundColor: '#f6ae2d'}}>
                            <div className='text-center text-xl'>{teamBStatsAvg?.name}</div>
                            <DataTable showGridlines stripedRows value={rotationsStatsB} rowGroupMode='subheader'
                            groupRowsBy="rotation" expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                            rowGroupHeaderTemplate={headerGroupTemplateB} >
                                <Column className='p-1 border-1 border-gray-900' align={'center'} field="set_match"
                                        footer="T" 
                                        alignHeader='center' headerClassName='p-1 border-1 border-gray-900' header="Set" />
                                
                                <Column className='p-1 border-1 border-gray-900' footer={(teamBStatsAvg.fbso_percentage*100).toFixed(2)} 
                                        alignHeader='center' headerClassName='p-1 border-1 border-gray-900' header="FBSO" 
                                        align={'right'} field={(item)=>formatNumber(item.fbso_percentage)} />
                                
                                <Column className='p-1 border-1 border-gray-900' footer={(teamBStatsAvg.so_percentage*100).toFixed(2)} 
                                        headerClassName='p-1 border-1 border-gray-900' alignHeader='center' header="SO" 
                                        align={'right'} field={(item)=>formatNumber(item.so_percentage)}  />
                                
                                <Column header="BP" alignHeader={'center'} headerClassName='p-1 border-1 border-gray-900' 
                                        field={(item)=>formatNumber(item.tra_percentage)} className='p-1 border-1 border-gray-900' 
                                        footer={(teamBStatsAvg.tra_percentage*100).toFixed(2)} align={'right'} />
                            </DataTable>
                            
                        </div>}
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default ShowGameStats