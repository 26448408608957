import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { PiArrowClockwise, PiCaretLeft, PiArrowsLeftRight, PiColumns, PiNotePencil, PiPersonSimpleThrow, PiRows, PiArrowUUpLeft, PiUsersFour, PiUsersThree, PiEraser } from 'react-icons/pi';
import StatisticsContext from '../StatisticsContext';
import MiniCourtRotationComponent from './MiniCourtRotationComponent';
import RallyComponent from './RallyComponent';
import { useNavigate } from 'react-router-dom'
import ManageTeamComponent from './ManageTeamComponent/ManageTeamComponent';
import { Divider } from 'primereact/divider';


function MenuButtonsComponent(props) {
    const {gridData,
        playersA,
        playersB,
        handleRotationA,
        handleRotationB,
        rotationA,
        rotationB,
        showSelectPosition, 
        setShowSelectionPosition,
        activeTeam, setActiveTeam, currentActionRefContext, setMatchContext, setSetMatchContext, 
        enabledAutoRotationContext, setEnabledAutoRotationContext} = useContext(StatisticsContext)
    const [statsDataURL, setStatsDataURL] = useState('')
    const navigate = useNavigate()

    const handleSetMatch = () => {
        setSetMatchContext(setMatchContext === 5 ? 1 : setMatchContext + 1)
    }

    const switchActiveTeam = () => {
        currentActionRefContext?.current.number === "ser" && activeTeam === 1 ? props.setTeamServing(gridData.teamB) : props.setTeamServing(gridData.teamA)
        activeTeam === 1 ? setActiveTeam(2) : setActiveTeam(1)
    }

    useEffect(()=>{
        gridData?.statisticsType && handleShowData()
    },[gridData?.statisticsType])

    const handleShowData = () => {
        if (gridData?.statisticsType === 'full' || gridData?.statisticsType === 'game'){
            setStatsDataURL(`/app/rotations_stats/${gridData?.matchAccessKey}`)
        } else if (gridData?.statisticsType === 'player'){
            setStatsDataURL('/app/show_stats')
        }
    }

    const restartRally = () => {
        props.restartRally()
    }

    return (
        <div className={`flex gap-4 justify-content-between ${!props.gridLayout ? 'flex-column': ''}`}>
            <div className='flex w-full flex-column gap-2 px-2'>
                <div className='flex flex-wrap w-full gap-2 justify-content-between'>
                    <div className='flex flex-1'>
                        <Button size='small' className={"w-max h-min p-2"} onClick={()=>navigate('/app/mystats')}><PiCaretLeft size={18} /></Button>
                    </div>
                    <div className='flex flex-1 gap-2 justify-content-end'>
                        {gridData?.statisticsType === 'player' && 
                        <Button size='small' className={"w-max h-min p-2"} onClick={()=>setShowSelectionPosition(!showSelectPosition)}><PiNotePencil size={18} /></Button> }
                        <Button size='small' className={"w-max h-min p-2"} onClick={restartRally}><PiEraser size={18} /></Button>
                        <Button size='small' className={"w-max h-min p-2"} onClick={handleSetMatch}>{setMatchContext} set</Button>
                        {/* <Button size='small' className={"w-max h-min p-2"} onClick={()=>props.setGridLayout(!props.gridLayout)}>{props.gridLayout ? <PiColumns size={18} /> : <PiRows size={18} />}</Button> */}
                        <ManageTeamComponent />
                        <Button size='small' className={`w-max h-min p-2 ${enabledAutoRotationContext ? 'bg-green-400' : 'bg-red-400'}`} onClick={()=>setEnabledAutoRotationContext(!enabledAutoRotationContext)}><PiArrowClockwise className='-rotate-90' size={18} /></Button>
                        {gridData.teamB && 
                        <Button size='small' className={"w-max h-min p-2"} onClick={switchActiveTeam}><PiArrowsLeftRight size={18} /></Button>}
                        <div className='border-x-1 border-300 m-1'></div>
                        <RallyComponent saveRally={props.saveRally} />
                    </div>
                </div>
                {/* <div className='font-bold text-4xl text-center'>{`${currentActionRefContext?.current?.label ? currentActionRefContext?.current.label : ''} ${props?.courtType}`}</div> */}
            </div>

            <div className='flex flex-wrap w-full'>
                <div className={`flex gap-2 align-items-end h-max justify-content-between flex-1 p-2 border-round`} style={{backgroundColor: activeTeam === 1 ? '#86bbd8' : ''}}>
                    <div className={`flex w-4 flex-column gap-1 h-full justify-content-between text-sm ${activeTeam === 1 ? ' font-bold' : ''}`}>
                        {
                            gridData?.statisticsType === 'player' ? 
                                <div className={'text-sm ' + activeTeam === 1 ? 'font-bold' : ''}>
                                    {`${props?.currentPlayer?.player?.first_name} ${props?.currentPlayer?.player?.last_name}`}
                                </div> :
                            gridData.teamA?.name && <div>{gridData.teamA.name}</div>
                        }
                        <div className={'flex align-items-center gap-2'}><PiPersonSimpleThrow size={24} /> {activeTeam === 1 && currentActionRefContext?.current?.number}</div>
                        <div className={'flex align-items-center gap-2'}><PiArrowClockwise className='-rotate-90' size={24} /> {rotationA}</div>
                    </div>
                    <div className='flex gap-1 w-8 justify-content-end'>
                        <Button size='small' onClick={()=>handleRotationA(rotationA)} className='p-2 bg-gray-500 border-500'>
                            <PiArrowClockwise className='-rotate-90' size={24} />
                        </Button>
                        {playersA && <MiniCourtRotationComponent players={playersA} />}
                    </div>
                </div>
                <Divider layout="vertical" />
                {gridData?.teamB?.id && 
                <div className={`flex gap-2 align-items-end h-max justify-content-between flex-1 p-2 border-round`} style={{backgroundColor: activeTeam === 2 ? '#f6ae2d' : ''}}>
                    <div className={`flex w-4 flex-column gap-1 h-full justify-content-between text-sm ${activeTeam === 2 ? ' font-bold' : ''}`}>
                        {gridData.teamB?.name && <div className='white-space-nowrap overflow-hidden text-overflow-ellipsis'>{gridData.teamB.name}</div>}
                        <div className={'flex align-items-center gap-2'}><PiPersonSimpleThrow size={24} /> {activeTeam === 2 && currentActionRefContext?.current?.number}</div>
                        <div className={'flex align-items-center gap-2'}><PiArrowClockwise className='-rotate-90' size={24} /> {rotationB}</div>
                    </div>
                    <div className='flex gap-1 w-8 justify-content-end'>
                        <Button size='small' onClick={()=>handleRotationB(rotationB)} className='p-2 bg-gray-500 border-500'>
                            <PiArrowClockwise className='-rotate-90' size={24} />
                        </Button>
                        {playersB && <MiniCourtRotationComponent players={playersB} />}
                    </div>
                </div>
                }
            </div>
        </div>
)
}

export default MenuButtonsComponent